import KexFallbackBlock from 'Kex/KexFallbackBlock';
import React, { useRef } from 'react';

import loadable from '@loadable/component';

import { IS_SERVER_CONTEXT } from '../Shared/Configs/EnvConfig';
import useOnScreen from '../Shared/Hooks/useOnScreen';
import ContentAreaItem from 'Models/App/ContentAreaItem.interface';

//TODO refactor with correct blocktypes
type PropType = {
  blockType: BlockTypeKey;
  isVisible: boolean;
  isNested: boolean;
  nextBlockProps?: ContentAreaItem;
  content: any;
};

const loadableComponents = {
  ProductListingBlock: loadable(
    () => import('Cms/Blocks/ProductListingBlock/ProductListingBlock')
  ),
  SubscriptionOffersBlock: loadable(
    () => import('Cms/Blocks/SubscriptionOffersBlock/SubscriptionOffersBlock')
  ),
  TextBlock: loadable(() => import('Cms/Blocks/TextBlock')),
  CTAContainer: loadable(
    () => import('Cms/Blocks/CTAContainerBlock/CTAContainerBlock')
  ),
  ContentBlock: loadable(() => import('Cms/Blocks/ContentBlock/ContentBlock')),
  MagazineListingBlock: loadable(
    () => import('Cms/Blocks/MagazineListingBlock')
  ),
  BrandListingBlock: loadable(
    () => import('Cms/Blocks/BrandListingBlock/BrandListningBlock')
  ),
  GiftCardBlock: loadable(
    () => import('Cms/Blocks/GiftCardBlock/GiftCardBlock')
  ),
  CookieBotDeclarationBlock: loadable(
    () => import('Cms/Blocks/CookieBotDeclarationBlock')
  ),
  ContactUsBlock: loadable(
    () => import('Cms/Blocks/ContactUsBlock/ContactUsBlock')
  ),
  BookClubListingBlock: loadable(
    () => import('Cms/Blocks/BookClubListingBlock/BookClubListingBlock')
  ),
};

export type BlockTypeKey = keyof typeof loadableComponents;

export const isBlockKeyType = (x?: string): x is BlockTypeKey =>
  !!x && Object.keys(loadableComponents).includes(x);

function KexLoadableBlock(props: PropType) {
  const ref = useRef<HTMLSpanElement>(null);
  const onScreen = useOnScreen({ ref });
  const { blockType, isVisible } = props;

  const LoadableComponent = loadableComponents[blockType];

  return isVisible || onScreen || IS_SERVER_CONTEXT ? (
    <LoadableComponent
      fallback={<KexFallbackBlock />}
      content={props.content}
    />
  ) : (
    <span ref={ref} />
  );
}

export default KexLoadableBlock;
